import React, { Suspense, lazy } from 'react';
import {BrowserRouter, Route} from "react-router-dom";

import { StateProvider } from './context/state-provider';
import reducer from './reducer/app-reducer';

// import { useDataLoader } from './hooks/useDataLoader';

import './theme/_colors.scss';
import './App.module.scss';

import SleepNavigation from "./components/SleepNavigation/SleepNavigation";
import LoadingAnimation from "./components/LoadingAnimation/LoadingAnimation";
import Layout from "./containers/Layout/Layout.js";

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

const Navigation = lazy(() => import("./components/Navigation/Navigation"));
const Overview = lazy(() => import("./containers/Overview/Overview"));
const Progress = lazy(() => import("./containers/Progress/Progress"));
const Availability = lazy(() => import("./containers/Availability/Availability"));
const Incidents = lazy(() => import("./containers/Incidents/Incidents"));

function App() {

    const initialState = {
        sleepNavigation: false,
        darkMode: true,
        sleepTime: 600,
        alarm: null
    };

    
    return (
        <>
            <ReactNotification />
            <StateProvider initialState={initialState} reducer={reducer}>
                <SleepNavigation />
                <Layout>
                    <BrowserRouter>
                        <Suspense fallback={<LoadingAnimation />}>
                            <Route path="/" component={Navigation} />
                            <Route path="/" exact component={Overview} />
                            <Route path="/progress" exact component={Progress} />
                            <Route path="/availability" exact component={Availability} />
                            <Route path="/incidents/:type" exact component={Incidents} />
                            <Route path="/incidents" exact component={Incidents} />
                        </Suspense>
                    </BrowserRouter>
                </Layout>
            </StateProvider>
        </>
    );
}

export default App;
