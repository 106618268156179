import React from 'react';
import {useStateValue} from "../../context/state-provider";

import classes from "./Layout.module.scss";
import Screensaver from "../../components/Screensaver/Screensaver";

const Layout = ( props ) => {

    const [{ darkMode } ] = useStateValue();

    return (
        <>
            <div className={[classes.Layout,darkMode ? 'dark' : 'light'].join(' ')}>
                {props.children}
                {/*<Screensaver/>*/}
            </div>

        </>
    );
};

export default Layout;
