import React from 'react';
import classes from'./SleepNavigation.module.scss'
import { useStateValue } from '../../context/state-provider';

const SleepNavigation = (props) => {
    const [ { sleepNavigation, sleepTime }, dispatch ] = useStateValue();

    const times = {
        '5 minutes' : 300,
        '10 minutes' : 600,
        '15 minutes' : 900,
        '30 minutes' : 1800,
        '60 minutes' : 3600,
        '1 Day' : 86400,
        '2 Days' : 172800,
    }

    const classState = sleepNavigation ? [classes.sidebar, classes.opened].join(' ') : [classes.sidebar, classes.closed].join(' ');

    return (
        <>
            <ul className={classState}>
                <li onClick={() => dispatch({
                        type: 'TOGGLE_SLEEP_NAVIGATION',
                        sleepNavigation: !sleepNavigation
                    })}>
                    <span className={classes.close}>Close</span>
                </li>
                <li><span className={classes.title}>Sleep time menu</span></li>
                {Object.entries(times).map( (entry, i) => 
                    <li key={i} className={[sleepTime == entry[1] ? classes.selected : ''].join(" ")} onClick={() => dispatch({
                        type: 'SET_ALARM_SLEEP_TIME',
                        sleepTime: entry[1],
                    })}><span>{entry[0]}</span></li>
                )}
            </ul>
        </>
    )

};

export default SleepNavigation;