import React from 'react';
import classes from "./LoadingAnimation.module.scss";

const LoadingAnimation = () => {
    return (
        <div className={classes.Loading}>
            <div className={classes.LoadingAnimation}><div></div><div></div><div></div><div></div></div>
        </div>
    );
};

export default LoadingAnimation;
