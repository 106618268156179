const reducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_DARKMODE':

            return {
                ...state,
                darkMode: !state.darkMode
            };

        case 'SET_ALARM_SLEEP_TIME':

            return {
                ...state,
                sleepTime: action.sleepTime ?? 0
            };


        case 'TOGGLE_SLEEP_NAVIGATION':

            return {
                ...state,
                sleepNavigation: !state.sleepNavigation
            };

        case 'SET_SNOOZE_SELECTION':

            return {
                ...state,
                alarm: action.alarm
            };

        default:
            return state;
    }
};

export default reducer;